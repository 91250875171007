import React, { Component } from 'react';
import { navigate,graphql,Link } from 'gatsby';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import ReactPixel from 'react-facebook-pixel';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
import { toast } from 'react-toastify';
import moment from 'moment';
// import TagManager from 'react-gtm-module';
import Helmet from 'react-helmet';
import Modal from 'react-awesome-modal';
import Layout from '../../../components/layout';
import Container from '../../../components/container';
import SwipeableViews from 'react-swipeable-views';
import DatePicker, { registerLocale } from 'react-datepicker';
import id from 'date-fns/locale/id';
import './package.css'
import header from '../../../components/header';
import qs from 'qs'
// import secure from '../../../static/images/secure.png'

export const query = graphql`
  query {
    allNetPack{
      edges {
        node {
          slug,productId,title,price,discount_price,thumbs{url,alt}
        }
      }
    }
    settings{
      data{
        idlefee,generalDiscount,insuranceCost,insuranceInfo,deposit
      }
    }
  }
`
const isSSR = typeof window === 'undefined';
if (!isSSR) {
  registerLocale('id', id);
}
const Spinner = () => (
  <svg className="lds-spinner" width="26px"  height="26px"  xmlns="http://www.w3.org/2000/svg" viewBox="20 20 60 60" preserveAspectRatio="xMidYMid" style={{background: 'none'}}><g transform="rotate(0 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(30 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(60 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(90 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(120 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(150 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(180 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(210 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(240 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(270 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(300 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
    </rect>
  </g><g transform="rotate(330 50 50)">
    <rect x="47" y="22.5" rx="9.4" ry="4.5" width="6" height="15" fill="#ffffff">
      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
    </rect>
  </g></svg>
)
const Label = styled.label`
  ${tw`text-sm`};
`

const HeadSub = styled.h2`
  margin-top: 0;
  text-transform: uppercase;
`

const TextInput = styled.input`
  ${tw`w-full px-2 mb-0`};
  &:focus{
    outline:none;
  }
`
const RowCard = styled.div`
  ${tw`flex flex-col md:flex-row`}
`
const CardHeader = styled.h4`
  ${tw`mb-3 mt-0`}
`
const PackageListHead = styled.h5`
  ${tw`mb-4 text-sm`}
`
const ColumnLeftCard = styled.div`
  ${tw`w-1/3 md:w-1/4`}
`
const ColumnRightCard = styled.div`
  ${tw`w-2/3 md:w-3/4 font-bold`}
`
const CheckoutButton = styled.button`
  ${tw`text-white rounded px-10 py-2 border-none cursor-pointer w-full`}
  background-color: #55BD22;
`
const Column = styled.div`
  ${tw`w-full md:w-1/2 mb-8 px-2 md:px-4`}
`
const ColumnRight = styled.div`
  ${tw`w-full md:w-1/2 mb-8 px-2 md:px-4 md:pt-10`}
`
const Card = styled.div`
  ${tw`rounded p-4 shadow-md text-sm`}
  background-color: #eee;
`
const NetPack = styled.div`
  ${tw`mb-2 border-grey bg-white border-solid border-transparent p-1 flex`}
  &:hover{
    border-color: #55BD22;
  }
`
const NetPackImg = styled.img`
  ${tw`w-1/5 mb-0`}
`
const NetPackDetail = styled.div`
  ${tw`flex flex-col justify-between px-1 md:px-2 md:pt-3 w-full`}
`
const NetPackTitle = styled.h6`
  ${tw`mb-0 text-sm md:mb-2 md:text-base`}
`
const NetPackFooter = styled.div`
  ${tw`flex flex-row justify-between content-center`}
`
const NetPackPrice = styled.span`
  ${tw`font-semibold text-grey`}
`
const NetPackLink = styled(Link)`
  ${tw`text-red text-xs font-bold no-underline hover:underline`}
`
const Confr = styled.div`
  ${tw`flex content-center py-4`}
`
const ItemGroup = styled.div`
  ${tw`pb-5`};
`
const PromoButton = styled.span`
  ${tw`float-left text-white cursor-pointer px-3`}
  border: solid 1px black;
  border-left-width:0;
  line-height:100%;
  display:flex;
  align-items: center;
  background-color: #55BD22;
`
const InsCheckbox = styled.input`
  position:absolute;
  opacity:0;
  & + label{
    position:relative;
  }
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border:solid 1px #38980a;
  }
  &:checked + label:before {
    background: #55BD22;
  }
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 11px;
    background: white;
    width: 3px;
    height: 3px;
    box-shadow: 
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
`
const Outlink = styled.a`
  color: #555;
  text-decoration: none;
  font-weight: 600;
  &:hover {
    color: #aaa;

  }
`
const Outlink2 = styled.a`
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  &:hover {
    color: #ddd;

  }
`
const SmallNote = styled.div`
  ${tw`text-xs`};
  padding-left: 10px;
  text-align: right;
`
const NoSubs = () => (
  <div>Langganan tidak ditemukan. Kendala? <Outlink2 href="https://mauorder.online/hi-travelwifi" target="_blank">klik disini</Outlink2></div>
)
class SubscribePage extends Component {
  state = {
    device_imei: '',
    fetch_subscription: false,
    subscription_exists: null,
    subscription: null,
    subConfirmed: false,
    checkLoad: false,
    package_id: null,
    sub_request: false,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // const tagManagerArgs = {
    //   gtmId: 'GTM-PSHLP3G',
    //   dataLayerName: 'paymentSuccess'
    // }
    
    // TagManager.initialize(tagManagerArgs);
    // this.applyPromoHandler('referral');
    // this.timer = null;
    // this.setState({
    //   device_imei : this.props.location.state != null?this.props.location.state.imei:'',
    // })
    console.log({abs:this.props.location.state})
  }

  componentWillUnmount() {
  }

  changeImeiHandler = event => {
    this.setState({ device_imei: event.target.value });
  }

  checkSubscription = () => {
    this.setState({fetch_subscription:true});
    axios.get(process.env.GATSBY_API_URL + 'api/v091/net_subscription', {
      params: {imei: this.state.device_imei}
    }).then((res) => {
      console.log({res})
      this.setState({subscription:res.data.subscription,package_id:res.data.subscription.ongoing_id});
      // set props imei,
      // tampilkan subscription beserta paket2 available,
    }).catch(err => {
      toast.error(err.response.status == 404 ? NoSubs : "Error saat mengambil data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
    }).finally(() => {
      this.setState({fetch_subscription:false});
    })

  }
  choosePackage = id => {
    this.setState({package_id:id});
  }
  subscribeHandler = event => {
    event.preventDefault();
    if (!this.state.subConfirmed){
      toast.error('Silahkan konfirmasi permintaan langganan.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      return 
    }
    this.processCheckout();
  }
  
  processCheckout(){  
    this.setState({sub_request:true})
    const payload = {
      package: this.state.package_id,
      subscription: this.state.subscription.id,
      
    };
              
    axios.post(process.env.GATSBY_API_URL + 'api/v091/net_token', payload)
      .then((res) => {
        snap.pay(res.data.token, {
          onSuccess: (result) => {
            console.log('success', result);
            // this.props.changePromo('');
            // this.props.changeReferral('');
            navigate('/checkout/finish');
          },
          onPending: (result) => {
            console.log('pending');
            // this.props.changePromo('');
            // this.props.changeReferral('');
            navigate('/checkout/pending');
          },
          onError: (result) => {
            console.log('error');
            console.log(result);
            axios.post(process.env.GATSBY_API_URL + 'api/v091/cancelorder', {
              order_id: res.data.order_id
            });
          },
          onClose: () => {
            console.log('customer closed the popup without finishing the payment');
            axios.post(process.env.GATSBY_API_URL + 'api/v091/cancelorder', {
              order_id: res.data.order_id
            });
          }
        })
      }).catch((err) => {
        toast.error(err.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true
        });
      }).finally(()=>{
        this.setState({sub_request:false})
      })
  }
  
  // gtag_report_conversion(value, url) {
  //   var callback = function () {
  //     if (typeof(url) != 'undefined') {
  //       window.location = url;
  //     }
  //   };
  //   gtag('event', 'conversion', {
  //       'send_to': 'AW-813419406/xnrcCPSV84oBEI6X74MD',
  //       'value': value,
  //       'currency': 'IDR',
  //       'transaction_id': '',
  //       'event_callback': callback
  //   });
  //   ga('send', 'event', 'checkout','proses order', this.state.email, value)
    
  //   return false;
  // }

  render() {  
    return (
      <Layout>
        <Helmet>
          <script src="/jquery.min.js" />
          <script src="/theme.js" />
          
          <link rel="stylesheet" href="/theme2.css" />
          <link rel="stylesheet" href="/gilroy.css" />
          <title>Subscribe Net Package | Wifi Republic</title>
        </Helmet>
        <Container style={{marginTop:'2rem'}}>
          <HeadSub>Layanan Perpanjangan Internet</HeadSub>
          <RowCard>
            <Column>
              <ItemGroup>
                <Label htmlFor="imeiDevice">Masukkan imei device Anda:</Label>
                <div style={{display:'flex'}}>
                  <TextInput id="imeiDevice" onChange={ this.changeImeiHandler } value={ this.state.device_imei } style={{border:"solid 1px",borderRightWidth:'0'}}/>
                  <PromoButton className={this.state.fetch_subscription?"disabled":""} onClick={ this.checkSubscription }>{this.state.fetch_subscription?<Spinner />:'Periksa'}</PromoButton>
                </div>
                <SmallNote>Kendala? <Outlink href="https://mauorder.online/hi-travelwifi" target="_blank">klik disini</Outlink></SmallNote>
              </ItemGroup>
              {!this.state.fetch_subscription && this.state.subscription !== null ?
              <Card>
                <RowCard>
                  <CardHeader>{this.state.subscription.id}</CardHeader>
                </RowCard>
                <RowCard>
                  <ColumnLeftCard>Nama</ColumnLeftCard>
                  <ColumnRightCard>: {this.state.subscription.name}</ColumnRightCard>
                </RowCard>
                <RowCard>
                  <ColumnLeftCard>Email</ColumnLeftCard>
                  <ColumnRightCard>: {this.state.subscription.email}</ColumnRightCard>
                </RowCard>
                <RowCard>
                  <ColumnLeftCard>Handphone</ColumnLeftCard>
                  <ColumnRightCard>: {this.state.subscription.phone}</ColumnRightCard>
                </RowCard>
                <RowCard>
                  <ColumnLeftCard>Alamat</ColumnLeftCard>
                  <ColumnRightCard>: {this.state.subscription.address}</ColumnRightCard>
                </RowCard>
                <RowCard>
                  <ColumnLeftCard>Paket Internet</ColumnLeftCard>
                  <ColumnRightCard>: {this.state.subscription.ongoing}</ColumnRightCard>
                </RowCard>
                <RowCard>
                  <ColumnLeftCard>Tanggal Mulai</ColumnLeftCard>
                  <ColumnRightCard>: {this.state.subscription.date_start}</ColumnRightCard>
                </RowCard>
                <RowCard>
                  <ColumnLeftCard>Tanggal Selesai</ColumnLeftCard>
                  <ColumnRightCard>: {this.state.subscription.date_end}</ColumnRightCard>
                </RowCard>
              </Card>:null}
            </Column>
            {!this.state.fetch_subscription && this.state.subscription !== null ?
            <ColumnRight>
             <PackageListHead>Pilih Paket Internet</PackageListHead>
             {this.props.data.allNetPack.edges.map(pack => (
               <NetPack 
                key={pack.node.productId}
                className={this.state.package_id==pack.node.productId?'selected pack-selected':''}
                onClick={()=>this.setState({package_id:pack.node.productId})}
              >
                <NetPackImg src={pack.node.thumbs[0].url} alt={pack.node.thumbs[0].alt} />
                <NetPackDetail>
                  <NetPackTitle>{pack.node.title}</NetPackTitle>
                  <NetPackFooter>
                    <NetPackPrice>
                      <CurrencyFormat
                        value={pack.node.discount_price}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp '}
                      />
                    </NetPackPrice>
                    
                  </NetPackFooter>
                </NetPackDetail>
              </NetPack>
             ))}
            <Confr>
              <InsCheckbox type="checkbox" id="subconf" style={{marginRight:'5px'}}
                  defaultChecked={this.state.subConfirmed}
                  onChange={()=>this.setState({subConfirmed: !this.state.subConfirmed})}
              />
              <label htmlFor="subconf" style={{fontSize:'.8rem',fontWeight:600}}>
                Data Langganan dan paket internet yang dipilih sudah benar
              </label>
            </Confr>
             <CheckoutButton onClick={this.subscribeHandler}>{this.state.sub_request?<Spinner />:'Checkout'}</CheckoutButton>
            </ColumnRight>
            :null}
          </RowCard>
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  netImei: state.netImei
});

const mapDispatchToProps = dispatch => ({
  updateImei: imei => dispatch({ imei }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubscribePage);